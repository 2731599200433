<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Entry Permissions ({{ totalEntryPermissions }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'maintenance.entry_permission.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Entry Permission
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="entry-permission-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs v-model="defaultStatus">
          <v-tab
            class="pa-3"
            v-for="item in tabItems"
            :key="item.index"
            :ref="item.index"
            :disabled="defaultStatus !== item.index && loading"
            @click="handleTabClick(item.index)"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search"
          @input="search"
        ></v-text-field>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        :headers="headers"
        :items="entryPermissions"
        v-model="selected"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        :server-items-length="totalEntryPermissions"
        @click:row="editItem"
        @update:options="fetch"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.is_active="{ item }">
          <v-checkbox
            v-model="item.is_active"
            disabled
            readonly
            class="mt-0 mb-0"
          />
        </template>
        <template v-slot:item.buildingName="{ item }">
          <div v-for="buildingItem in item.buildings" :key="buildingItem.id">
            {{ buildingItem.name }}
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div v-if="item.addedDate">{{ item.addedDate }}</div>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <div v-if="item.updatedDate">{{ item.updatedDate }}</div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * EntryPermission list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'EntryPermissionsPage',
  mixins: [FilterTableMixin, ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'header',
      sortOrder: true,
      headers: [
        {
          text: 'Entry Permission',
          value: 'label',
        },
        {
          text: 'Building(s)',
          value: 'buildingName',
          sortable: false,
        },
        {
          text: 'Active',
          value: 'is_active',
          align: 'center',
        },
        {
          text: 'Updated Date',
          value: 'updated_at',
        },
        {
          text: 'Added date',
          value: 'created_at',
        },
      ],
      tabItems: [
        { tab: 'Active', index: 0 },
        { tab: 'Archived', index: 1 },
      ],
      selected: [],
      actions: [],
      options: {},
      defaultStatus: 0,
    }
  },

  created() {
    this.fetchInitParams()
    this.clearEntryPermissions()
    this.fetch(this.options)
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      entryPermissions: (state) => state.entryPermission.list,
      listMeta: (state) => state.entryPermission.listMeta,
      permissions: (state) => state.auth.permissions,
      totalEntryPermissions: (state) =>
        state.entryPermission.totalEntryPermissions,
      params: (state) => state.entryPermission.params,
      scrollPosition: (state) => state.entryPermission.scrollPosition,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions(
        [PERMISSION.ENTRY_PERMISSIONS_CREATE],
        this.permissions
      )
    },
  },

  methods: {
    ...mapActions({
      getEntryPermissions: 'entryPermission/getEntryPermissions',
    }),

    ...mapMutations({
      clearEntryPermissions: 'entryPermission/clearEntryPermissionList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'entryPermission/setParams',
      setScrollPosition: 'entryPermission/setScrollPosition',
    }),

    filterList(filterValue) {
      this.clearEntryPermissions()
      this.fetch(this.options, filterValue)
    },

    activeAction() {
      this.clearEntryPermissions()
      this.fetch(this.options)
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetch(this.options, this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearEntryPermissions()
      this.fetch(this.options)
    }, 600),

    async fetch(options, page = 1) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        options: {
          page: options?.page || 1,
          itemsPerPage: options?.itemsPerPage
            ? options?.itemsPerPage === -1
              ? this.totalTags
              : options.itemsPerPage
            : 25,
          sort: options?.sortBy[0]
            ? options?.sortDesc[0]
              ? '-' + options.sortBy[0]
              : options.sortBy[0]
            : '',
        },
        status: this.defaultStatus,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.setParams(params)

      this.loading = true
      await this.getEntryPermissions(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },

    editItem(item) {
      this.$router.push({
        name: 'maintenance.entry_permission.details',
        params: { id: item.id },
      })
    },

    fetchInitParams() {
      this.defaultStatus = this.params.status

      this.options = this.params.options

      this.filter.search = this.params.search
    },

    handleTabClick(status) {
      this.defaultStatus = status
      this.defaultFilter = 'created_at'
      this.activeAction()
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return

      const scrollPosition = this.scrollPosition
      this.setScrollPosition(0)

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}

.v-list-item__title {
  cursor: pointer;
}
</style>
